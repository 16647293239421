import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
  },
  operateType: {
    displayName: 'operateType',
  },
  operateName: {
    displayName: 'operateName',
  },
  operateData: {
    header: false,
    displayName: 'operateData',
  },
  createdAt: {
    type: IKDataEntity.Types.Date,
    displayName: 'Datum/Uhrzeit',
  },
}
const config = {
  load: async function (filter) {
    return (await hillo.get('AccessLog.php', {
      op: 'getAllRecords', ...filter,
    })).content
  },
}

export default IKDataEntity.ModelFactory(entity, config)
